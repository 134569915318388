import React from "react"
import { SignUpForm } from "../components/main/join"
import Layout2 from "../components/main/layout2"
import styled from "styled-components"
import { CompanyLogo } from "../lib/componentHelpers"

const SignupWrapper = styled.div`
  background-color: #19232d;
  width: 100%;
  padding-bottom: 2em;
  min-height: 100vh;
`

const BSLogoDiv = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SignUpPage = () => {
  return (
    <SignupWrapper>
      <Layout2>
        <SignUpForm />
      </Layout2>
      <BSLogoDiv>
        <CompanyLogo />
      </BSLogoDiv>
    </SignupWrapper>
  )
}
export default SignUpPage
